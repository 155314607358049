// common
.card a { position: relative; overflow: hidden; display: block; padding-bottom: 100%; border-radius: 10px; background-position: 50%; background-repeat: no-repeat; background-size: cover;}
// .card.new a {padding-bottom: 80%;}
.card.channel {
    a {padding-bottom: 62%;}
    .filter-btns span {text-align: center; padding: 0; line-height: 27px; margin-right: 0; font-size: 14px; cursor: pointer; width: 75px;}
}
.card .card-img-top { position: absolute; transition: all,.4s; }
.overlay .mask{opacity:0;-webkit-transition:.4s ease-in-out;transition:.4s ease-in-out}
.view .mask{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;overflow:hidden;background-attachment:fixed}
.avatar{ background-position: center; background-size: cover;}
.avatar-md{width:28px;height:28px;font-size:1rem;}
.avatar-xxxl{width: 6rem; height: 6rem;}
.contents-wrap, .contents-inner-wrap { display: flex; flex-wrap: wrap;}
.contents-item {
    width: 23.7%; margin: 0 20px 20px 0; background-color: transparent;
    img {border-radius: 10px;}
    h1{font-weight: 800; font-size: 48px;}
    .status-txt{
        position:absolute;top:46%;left:0%;font-size:16px;font-weight:500; width: 100%;
        text-align: center; color: white;
    }
}
.contents-item:nth-child(4n) {margin: 0 0 20px 0;}
.filter-btns span{border:1px solid $yellow;padding:3px 19px;border-radius:10rem;line-height:30px;margin-right:1rem;margin-bottom:3px;color:$yellow;display:inline-block;font-weight: 500;cursor: pointer;}
.filter-btns span.active { background: $yellow; color: #121212; font-weight: 700;}
.no-data{border:1px solid #5a5e6a;border-radius:10px;text-align:center;padding:8rem;font-size:18px;color:#7f828c}
.tab-sticky { position: sticky; top: 60px; z-index: 10; }
.modal.confirm {
    .modal-footer { padding: 0; justify-content: space-around; border-top: 1px solid #E4E6EA;
        a {font-size: 16px; flex: 1; text-align: center; padding: 17px; margin: 0;}
        a:nth-child(2) { border-left: 1px solid #E4E6EA;}
    }
}
.modal.loading .modal-content {background-color: transparent;}
.side-bar {
    .top-wrap { background: #29292B; padding: 20px; border-radius: 5px;
        .btn {height: 37px;}
    }
}
.btn-group {
    .dropdown-toggle{width:180px;height:52px;border:1px solid #fff !important;background-color: transparent !important;font-weight:400;font-size:14px!important;color:#5a5e6a;display:flex;align-items:center;justify-content:space-between}
    .dropdown-toggle::after {display: none;}
    .dropdown-menu {width: 180px; padding: 10px 1rem; margin-top: 5px;
        .dropdown-item { padding: 0.75rem 0; }
    }
}
.header {
    height: 56px; background-color: $black; border: none; align-items: center; justify-content: center;
    .header-nav {justify-content: flex-end; align-items: center;
        img {width: 24px;}
        .dropdown-menu { background-color: #3B3F4A; border-radius: 10px; min-width: 568px; padding: 20px 28px; max-height: 90vh; overflow-y: scroll; margin-right: -5rem; margin-top: 10px;}
        .dropdown-divider { border-top: 1px solid #5A5E6A; margin-top: 0;}
        .dropdown-menu::-webkit-scrollbar { display: none; }
        .dropdown-menu::-webkit-scrollbar-thumb { background: rgba(0,0,0,0); }
    }
    .alarm {position: relative; margin-right: 0.5rem;
        span{position:absolute;height:4px;width:4px;top:0px;right:0px;border-radius:100px;color:#fff;font-size:8px;line-height:14px;font-weight:700;text-align:center;background-color: $pink;}
    }
    .alarm-item-img {width: 64px; height: 64px;border-radius: 10px; background-position: 50%; background-repeat: no-repeat; background-size: cover;}
    .btn_login{display:flex;flex-direction:row;justify-content:center;align-items:center;width:77px;height:27px;border-radius:4px;font-size:12px;color:#fff;border:1px solid}
    .header-nav .nav-link:hover, .header-nav .nav-link:focus {
        color: white;
    }
    .lang.btn-group .dropdown-toggle {width: 70px; height: 30px; margin-left: 2rem; color: white; justify-content: flex-start; padding-left: 0; border: none !important;}
    .header-nav .lang .dropdown-menu { min-width: 80px; padding: 10px 20px; width: 90px; text-align: center;}
}

.footer {
    padding: 60px; background: #121214; border: none; color: #888; align-items: flex-start; font-size: 16px; line-height: 2rem; margin-top: 2rem;
    .logo2 {width: 192px;}
    ul {align-items: center;
        span {width: 1px; height: 12px; background: #888;margin-top: 0px; display: inline-block;}
    }
    li {padding: 0 10px;color: #E6E6E7;}
    span.d-md-block { display: inline !important; }
}

.home {
    .contents-title {font-weight:700;font-size:24px;line-height:35px;margin-bottom:1rem}
    .home-banner {background-position: 50%;background-repeat: no-repeat;background-size: cover;height: 580px;width: 100%;}
}

.login {
    .email-auth-complete {font-size: 12px; position: absolute; top: 45px; right: 12px;}
    .btn-white {border: none !important;}
}
.ios .login .email-auth-complete {top: 50px;}

.search {
	.keyword {
        input {border: none; border-bottom: 1px solid white; border-radius: 0 !important; padding-left: 2rem;}
        .btn-search {position: absolute; top: 15px; left: 0; width: 24px; cursor: pointer;}
        .btn-close {position: absolute; top: 15px; right: 0; width: 20px; height: 20px; cursor: pointer;}
    }
    .keyword-btns {
        span{border:1px solid #7F828C;padding:7px 16px;text-align: center; font-size: 18px; color: #9DA0A8; font-weight: 400;border-radius:10rem;line-height:30px;margin-right:1rem;margin-bottom:13px;display:inline-block;cursor: pointer;font-style: normal;}
    }
    .search-item {border-bottom: 1px solid #3B3F4A; padding: 24px 36px;display: flex; align-content: center; justify-content: space-between; font-size: 18px; color: #BEC1C7; cursor: pointer;}
}
.modal.term {
    .modal-content {background-color: #3B3F4A; min-height: 80vh;}
}
.serial-modal .modal-footer {border-top: 1px solid #DDD !important}
.item .dropdown-menu { min-width: 80px; transform: translate(-7px, 19px) !important; cursor: pointer;}
.dropdown::marker {display: none; content: none;}
.contents-upload {
    .btn-upload {width: 176px; height: 176px; border-radius: 10px; display: flex; align-items: center; justify-content: center; background-color: #29292B; overflow: hidden; position: relative;
        video { width: 100%; height: 100%; position: absolute; object-fit: cover;}
    }
}
.my-channel {
    .top-banner { height: 392px; background-size: cover; background-position: center; margin-top: -64px; position: relative;
        .bottom-wrap{background-image:url('../assets/img/i_gradient.png');background-size: cover;position:absolute;width:100%;bottom:0;left:0;padding-bottom:1rem;overflow: hidden;}
    }
    .contents-item { width: 23%;
        .fs-20 {font-size: 16px !important;}
    }
    .card.channel {
        a {padding-bottom: 100%;}
    }
    .table-wrap { font-size: 16px;
        .table-row {
            div {display: inline-block;}
        }
        .table-body .table-row{background:#29292b;padding:12px;border-radius:4px;margin-bottom:.75rem}
    }
    //
    .pic-wrap{width:176px;height:176px;background:#29292b;border-radius:10px;display:flex;align-items:center;justify-content:center;cursor: pointer;background-size: cover;background-position: center;}
    .url-fixed {position: absolute; top: 15px; left: 12px;font-size: 14px; }
    .form-control[readonly] {border-color: white;}
}
//
.modal-img {
    .modal-dialog {margin: 0 auto;}
    .modal-content {background-color: transparent;}
}
.qna {
    .sel-img {width: 100px; height: 100px; background-color: #EEEEEE; display: flex; align-items: center; justify-content: center; cursor: pointer;}
    .img-wrap {width: auto; height: 100px; position: relative; margin-left: .5rem; background: #eee;
        .i_delete {position: absolute; top: 0; right: 0;cursor: pointer;}
    }
}
.accordion-button {display: block;padding: 1rem 0}
.accordion-button::after {display: none;}
.accordion-button:not(.collapsed), .accordion-button:focus { color: inherit; background-color: white; box-shadow: none; border-radius: 0 !important;}
.accordion-item { border: none; border-bottom: 1px solid #EEE; border-radius: 0;}
.accordion-body {padding: 1rem 0}
.form-control.white-bg { background-color: #FFF !important; }
.btn-yellow-signup:disabled { background-color: #FFF !important; opacity: 1; color: black;}
.form-control.yellow-bg, .form-control.yellow-bg:focus, .form-control.yellow-bg::placeholder {background-color: #F9D247 !important; color: black;}
.form-control.yellow-bg+span {color: black !important;}
.white .form-label {color: white;}

/* **************************************** *
  * MOBILE
  * **************************************** */
@media (min-width: 1400px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1200px;
    }
    .container-xxl { max-width: 1400px; }
}

@media (max-width: 1400px) {
    .contents-wrap .contents-item {width: 23.6%;}
    .my-channel {
        .tab-wrap { overflow-x: scroll;
            .tab-inner {width: 100vw;}
        }
        .contents-wrap .contents-item {width: 22.7%;}
    }
}

@media (max-width: 1200px) {
    .contents-wrap .contents-item {width: 23.3%;}
    .my-channel {
        .contents-wrap .contents-item {width: 22.1%;}
    }
}

@media (max-width: 1024px) {
    // .contents-wrap .contents-item {width: 23.3%;}
}

@media (max-width: 992px) {
    .contents-wrap .contents-item {width: 47.7%; }
    // .contents-wrap .contents-item:nth-child(4n) {margin: 0 20px 20px 0;}
    .my-channel {
        .contents-wrap .contents-item {width: 47.4%; }
        .contents-wrap .contents-item:nth-child(2n) {margin-right: 0 !important;}
    }
}

@media (max-width: 767px) {
    .fs-16 { font-size: 12px !important; }
    .fs-18 { font-size: 14px !important; }
    .fs-20 { font-size: 16px !important; }
    .fs-24 { font-size: 17px !important; }
    .fs-28 { font-size: 18px !important; }
    .fs-36 { font-size: 20px !important; }
    h3, .h3 { font-size: 18px; }
    h5, .h5, .form-check-label { font-size: 13px !important; }
    .modal-body { padding: 30px 20px; }
    .filter-btns span { padding: 1px 15px; line-height: 26px; margin-right: 10px; font-size: 11px !important; line-height: 20px !important; height: 23px;}
    .home h3 { font-size: 16px; }
    .nav-tabs .nav-link {font-size: 14px;}
    .contents-wrap {
        display: block; overflow-x: scroll;
        .fs-18 { font-size: 14px !important; }
        .contents-inner-wrap {display: block; width: 285vw;}
        .contents-item { width: 35vw; display: inline-block; margin: 0 10px 10px 0;
            .fs-20 { font-size: 12px !important; }
            .fs-16 { font-size: 11px !important; }
            .fs-12 { font-size: 10px !important; }
        }
        .contents-item.new {width: 49%; padding-right: 1rem;margin-right: 0 !important;}
    }
    .my-channel {
        .contents-wrap .contents-item {width: 48%; padding-right: 0; margin-right: 10px !important;}
        .tab-wrap { .tab-inner {width: 200vw;} .tab-inner.one {width: 90vw;} }
        .req-creator.tab-wrap {
            .tab-inner {width: 400vw;}
            .tab-inner.ko {width: 300vw;}
            a {letter-spacing: -1px; font-size: 12px; }
        }
        .top-banner {height: 262px;
            .bottom-wrap {padding-top: 4.5rem;background: none !important;
                .fs-32 {font-size: 22px;}
                .fs-18 { font-size: 14px !important; max-height: 86px; overflow: hidden;}
            }
            .justify-content-between {display: inline;}
        }
        .table-wrap .table-row {width: 200vw;}
        .width10 { width: 9rem !important; }
    }
    .card-mini-thumb { border-radius: 10px; background-position: 50%; background-repeat: no-repeat; background-size: cover; width: 55px; height: 55px;}
    .modal .btn-group .dropdown-toggle {width: 100px;}
    .tab-wrap {overflow-x: scroll;}
    .tab-inner {width: 220vw;}
    .no-data { padding: 4rem 0; font-size: 13px;}
    .header {
        padding: 0 7px;
        .header-nav .dropdown-menu { width: calc(100vw - 30px); min-width: calc(100vw - 30px); margin: 0; top: 50px !important; left: 15px !important; transform: translate3d(0, 0px, 0px) !important; padding: 20px;
            div.pt-2 { width: 43vw;}
        }
        .header-nav .lang .dropdown-menu {margin-right: auto;top: 40px !important; left: -21px !important;}
        .dropdown {position: static;}
        .lang.btn-group .dropdown-toggle {font-size: 12px !important;width: auto;padding-right: 0; margin-left: 1.5rem;}
        .btn_login { font-size: 10px; width: 57px; height: 27px; }
    }
    .footer {
        padding: 0px 20px 40px; line-height: 2.5rem;
        .footer-info {font-size: 12px !important; line-height: 25px;}
        li {font-size: 12px;}
    }
    .home {
        .home-banner {height: 180px;}
        .m-popular-title {width: calc(100% - 63px);}
    }
    .search {
        .dropdown-toggle { width: 132px; }
        .dropdown-menu {width: 132px;}
        .dropdown-item { font-size: 12px; }
        .tab-inner {width: 100%;
            .nav-item {padding: 0 10px;
                a{padding: 0}
            }
        }
        .keyword .btn-close { right: 10px; }
    }
    .contents-upload {
        // .btn-upload { height: auto; }
        .agree-label {width: calc(100% - 100px)}
    }
    //
    .qna {
        .sel-img {width: 80px; height: 80px;}
        .img-wrap {width: auto; height: 80px; margin-left: .3rem;}
    }
}

@media (max-width: 657px) {
    // .contents-wrap .contents-item {width: 48.9%;}
}
@media (max-width: 576px) {
    // .contents-wrap .contents-item {width: 48.0%;}
}


/*-------------------------------------------------추가 css*/
.FlexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.bg-none{
    background: transparent !important;
    background-color: transparent !important;
}

.tab-stickyContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #29292E;
}
.tab-stickyContainer h2{
    margin-bottom: 0;
}
.stickyContainerRight{
    display: block;
    content: "";
    position: absolute;
    width:100%;
    height: 100%;
    background: #29292e;
    left: -100%;
    top: 0px;
}

.stickyContainerLeft{
    display: block;
    content: "";
    position: absolute;
    width:100%;
    height: 100%;
    background: #29292e;
    left: 100%;
    top: 0px;
}

.border1px{
    width: 100%;
    height: 1px;
    background: #29292e;
}

.couponAuth{
    font-size: 12px;
    position: absolute;
    top: 45px;
    right: 12px;

}

.trash_can_Action{
    text-align: right;
    filter: brightness(0.6);
}
.trash_can_Action:hover{
    filter:none;
}
.alarmDeleteBtn{
    text-align: right;
    color: white;


}
.note{
display: flex;
justify-content: flex-start;
align-items: flex-start;
position: fixed;
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
background: rgba(0,0,0,.5);

}
.TableContainer {
    transition: all .2s ease;
    height: 0px !important;
    overflow: hidden !important;
}
.cclCheck {
    position: relative;
    display: flex !important;
    flex-wrap: nowrap !important;

}

.note{
    display: block;
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    border: 5px;
    max-width: 500px;
    white-space:pre;
    background-color: #121214;
}

.zin{z-index: 101;}

.ccl1{
    position: relative;
}


.enterSvg{
    position: absolute;
    right: 10px;
    top: 65%;
}


.modalFooter{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:10px;
}

.modalCustom{
    background-color: transparent !important;
}

.modalCustom .modal-content {
    background-color:transparent; /* 배경 투명도 조절 */
    border: none; /* 기본 테두리 제거 */
}

.modalImgContainer{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}


.fontMid {
    font-size: 18px !important;
}

@media (max-width: 764px) {
    .fontMid {
        font-size: 12px !important;
    }
}